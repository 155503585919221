<template>
  <b-card-actions
    title="Employees"
    action-refresh
    @refresh="onRefresh"
    ref="mainCard"
  >
    <div class="d-flex justify-content-between align-items-center mb-1">
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Sort</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-100"
        />
        <b-button
          variant="primary"
          size="sm"
          class="d-flex ml-1"
          style="height: 2.142rem"
          :to="{ name: 'hrm/employee/add' }"
        >
          <feather-icon icon="PlusIcon" size="14" />
          Employee
        </b-button>
        <download-excel
          :data="exportItems"
          :fields="exportFields"
          :filename="'Employee'"
          :sheetname="'Employee'"
          class="cursor-pointer ml-1"
        >
          <b-button
            variant="outline-secondary"
            size="sm"
            class="d-flex mr-1 export-btn"
          >
            <feather-icon icon="FileIcon" size="14" class="text-success" />
            <label style="font-size: 14px; margin-left: 5px; cursor: pointer"
              >Export</label
            >
          </b-button>
        </download-excel>
      </div>
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Search</label>
        <b-form-input
          id="searchInput"
          v-model="filter"
          size="sm"
          type="search"
          placeholder="Search"
        />
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          @filtered="onFiltered"
          header-class="header-wrap"
        >
          <template #cell(profile_photo_url)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(is_active)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <!-- <feather-icon
                icon="TrashIcon"
                @click="deleteEmployee(data.value)"
                size="20"
                class="text-danger"
                style="margin-right: 5px"
                v-if="hasPermission('delete user')"
              ></feather-icon> -->
              <b-link
                :to="{
                  name: 'hrm/employee/detail',
                  params: { id: data.value },
                }"
              >
                <feather-icon
                  icon="EditIcon"
                  size="20"
                  class="text-primary"
                ></feather-icon>
              </b-link>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            Showing
            {{ perPage > totalRows ? totalRows : perPage }} of
            {{ totalRows }} entries
          </span>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0"
            @change="handlePageChange"
          />
        </div>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<style>
.header-wrap {
  white-space: nowrap !important;
}

.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        {
          key: "hrcode",
          label: "HR Code",
        },
        {
          key: "profile_photo_url",
          label: "Avatar",
        },
        { key: "name", label: "Full Name", sortable: true },
        "email",
        { key: "department", label: "Department", sortable: "true" },
        { key: "team", label: "Position", sortable: "true" },
        { key: "is_active", label: "Status", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      status: [
        {
          1: "Active",
          0: "Inactive",
        },
        {
          1: "light-success",
          0: "light-secondary",
        },
      ],

      // export excel
      exportFields: {
        "HR Code": "hrcode",
        Name: "name",
        Level: "job_level",
        Email: "email",
        Department: "department",
        Position: "team",
        Birthday: "birthday",
        Age: "age",
        Birthplace: "birthplace",
        Gender: "gender",
        Religion: "religion",
        Nationality: "nationality",
        "Marital Status": "marital_status",
        Dependent: "dependent",
        "Number of Identity": "nik",
        "Place of Identity": "place_of_identity",
        Status: "is_active",
      },
      exportItems: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    // fetch data
    this.fetchData();
  },
  methods: {
    onRefresh() {
      this.fetchData();
    },

    fetchData(filter = {}, page = null) {
      this.$refs["mainCard"].showLoading = true;
      let params = {};

      if (page != null) {
        params.page = page;
      }

      this.callApi({
        method: "GET",
        url: "/users/getAll",
        params: params,
        success: (response) => {
          response.result.data.forEach((item) => {
            const dps = item.department
              .map((d) => {
                return d.name;
              })
              .join(", ");

            const pst = item.team
              .map((t) => {
                return t.name;
              })
              .join(", ");

            this.items.push({
              hrcode: item.hrcode,
              profile_photo_url: item.profile_photo_path,
              name: item.name,
              email: item.email,
              department: dps,
              team: pst,
              is_active: item.is_active,
              actions: item.id,
            });

            // delete items hrcode 1101101
            this.items = this.items.filter((item) => {
              return item.hrcode !== "1101101";
            });

            this.exportItems.push({
              hrcode: item.hrcode,
              name: item.name,
              job_level: item.job_level != null ? item.job_level.name : "",
              birthday: item.birthday,
              birthplace: item.birthplace,
              gender: item.gender,
              religion: item.religion,
              marital_status: item.marital_status,
              dependent: item.dependent,
              nationality: item.nationality,
              nik: item.number_of_identity,
              place_of_identity: item.place_of_identity,
              age: item.age,
              email: item.email,
              phone: item.phone,
              department: item.department != null ? item.department.name : "",
              team: item.team != null ? item.team.name : "",
              is_active: item.is_active == 1 ? "Active" : "Inactive",
            });

            this.exportItems = this.exportItems.filter((item) => {
              return item.hrcode !== "1101101";
            });
          });
          //
          this.totalRows = response.result.total - 1;
          this.$refs["mainCard"].showLoading = false;
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to fetch data",
              variant: "error",
            },
          });
          this.$refs["mainCard"].showLoading = false;
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handlePageChange(data) {
      this.fetchData({}, data);
    },
    deleteEmployee(id) {
      this.callApi({
        method: "DELETE",
        url: "/users/delete/" + this.hashid(id),
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Delete successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to delete",
              variant: "error",
            },
          });
        },
      });
    },
  },
};
</script>
